.profile {
  padding-top: 40px;
  max-width: 100%;
  position: relative;
}

@media screen and (max-width: 745px) {
  .profile {
    grid-template-columns: 120px 1fr;
    row-gap: 30px;
  }
}

@media screen and (max-width: 585px) {
  .profile {
    grid-template-columns: 1fr;
    row-gap: 33px;
  }
}

@media screen and (max-width: 405px) {
  .profile {
    max-width: 88%;
    margin: 0 auto;
  }
}
