.element__like {
  background-color: aqua;
  color: black;
  border-radius: 15px;
  justify-self: flex-end;
  width: fit-content;
  height: 100%;
  border: 1px solid black;
  grid-row: 1/3;
  grid-column: 2/3;
}

.element__like:hover {
  opacity: 0.5;
  cursor: pointer;
}
