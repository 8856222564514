.edit__submit-btn {
  padding: 0;
  border: 1px solid black;
  color: black;
  background-color: aqua;
  width: 358px;
  height: 50px;
  align-self: center;
  margin-bottom: 36px;
  margin-top: 38px;
  border-radius: 25px;
  max-width: 90%;
}

.edit__submit-btn:hover {
  transition: 0.5s;
  opacity: 0.8;
  cursor: pointer;
}

@media screen and (max-width: 585px) {
  .edit__submit-btn {
    width: 238px;
    height: 46px;
  }
}
