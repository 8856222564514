.popup {
  position: fixed;
  display: flex;
  visibility: hidden;
  transition: all .5s linear;
  opacity: 0;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}