.input__field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input__field:nth-child(1) {
  margin-top: 15px;
}

.input__field:last-child {
  margin-bottom: 10px;
}
