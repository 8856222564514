.input__form_credentials {
  background-color: unset;
  color: white;
  border: solid 2px #000;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

.input__form_credentials::placeholder {
  color: #000;
}
