.profile__edit-button {
  display: none;
  width: 28px;
  height: 28px;
  padding-top: 50px;
  background: url(../../../images/EditButton.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  border: 0;
}

.profile__edit-button:hover {
  transition: 0.5s;
  opacity: 0.5;
  cursor: pointer;
}

@media screen and (max-width: 585px) {
  .profile__edit-button {
    background-size: 18px;
    right: 0px;
    position: absolute;
  }
}

@media screen and (max-width: 405px) {
  .profile__edit-button {
    padding: 30px 10px 0;
    margin-left: 0;
  }
}
