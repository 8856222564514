.header__logo {
  height: 50px;
  width: auto;
}
/*
@media screen and (max-width:550px){
  .header__logo{
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

@media screen and (max-width:500px){
  .header__logo {
    padding-left: 8px;
    height: 24px;
    width: 126px;
  }
}

@media screen and (max-width:405px){
  .header__logo {
    padding-left: 27px;
  }
}*/