.input__form-error {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: red;
  opacity: 0;
  position: absolute;
  top: calc(70% + 6px);
  transition: 0.3s;
}
