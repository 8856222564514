.profile__info {
  /*display: grid;
  grid-template-columns: 1fr 26px;
  max-width: 415px;
  height: 74px;*/
  width: 90%;
  padding-top: 22px;
  margin: 0 auto;
}

@media screen and (max-width: 585px) {
  .profile__info {
    padding: 0;
    grid-template-columns: 1fr;
  }
}
