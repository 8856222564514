.elements {
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  column-gap: 17px;
  max-width: 100%;
  z-index: 1;
}

@media screen and (max-width: 745px) {
  .elements {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 500px) {
  .elements {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 405px) {
  .elements {
    max-width: 88%;
    margin: 0 auto;
  }
}
