.element__location {
  padding-left: 20px;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.8rem;
  white-space: wrap;
  overflow: hidden;
  height: 100%;
  margin: 0;
  grid-row: 1/2;
  grid-column: 1/2;
}