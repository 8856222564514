.footer__copyright {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.4rem;
  margin: 0;
  padding: 67px 0 60px;
  color:#000;
}

@media screen and (max-width:585px){
  .footer__copyright {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 60px 0 50px;
  }
}

@media screen and (max-width:405px){
  .footer__copyright {
    font-size: .87rem;
    line-height: 1.1rem;
    padding: 50px 0 36px;
  }
}