@font-face {
  font-family: 'Inter';
  src: url(../../fonts/Inter-3.19/InterWeb/Inter-Regular.woff) format('woff'),
    url(../../fonts/Inter-3.19/InterWeb/Inter-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Bold';
  src: url(../../fonts/Inter-3.19/InterWeb/Inter-Bold.woff) format('woff'),
    url(../../fonts/Inter-3.19/InterWeb/Inter-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-ExtraBold';
  src: url(../../fonts/Inter-3.19/InterWeb/Inter-ExtraBold.woff) format('woff'),
    url(../../fonts/Inter-3.19/InterWeb/Inter-ExtraBold.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Black';
  src: url(../../fonts/Inter-3.19/InterWeb/Inter-Black.woff) format('woff'),
    url(../../fonts/Inter-3.19/InterWeb/Inter-Black.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Light';
  src: url(../../fonts/Inter-3.19/InterWeb/Inter-Light.woff) format('woff'),
    url(../../fonts/Inter-3.19/InterWeb/Inter-Light.woff2) format('woff2');
}
@font-face {
  font-family: 'Inter-Medium';
  src: url(../../fonts/Inter-3.19/InterWeb/Inter-Medium.woff) format('woff'),
    url(../../fonts/Inter-3.19/InterWeb/Inter-Medium.woff2) format('woff2');
}

.page {
  background-color: #74c365;
  color: black;
  font-family: 'Inter', Arial, sans-serif;
  margin: 0 auto;
  padding: 0;
  max-width: 880px;
}

@media screen and (max-width: 930px) {
  .page {
    max-width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .page {
    max-width: 100%;
  }
}
